export default class AuthModel {
  constructor(formErrors) {
    this.formErrors = formErrors;
    this.formData = {
      username: null,
      password: null
    };

    this.apiAttributes = {
      username: "username",
      password: "password"
    };
  }

  setErrors(invalidFields) {
    let fieldsNames = {};
    for (let field in invalidFields) {
      if (field in this.apiAttributes)
        fieldsNames[this.apiAttributes[field]] = invalidFields[field];
    }
    this.formErrors.setErrors(fieldsNames);
  }

  getApiData() {
    return {
      username: this.formData.username,
      password: this.formData.password
    };
  }
}
